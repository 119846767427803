import { graphql } from 'babel-plugin-relay/macro'
import React, { useCallback, useState } from 'react'
import { QueryRenderer, commitMutation } from 'react-relay'
import { useSearchParams } from 'react-router-dom'

import environment from '../../Environment'
import { Error, Loader } from '../../components'
import Config from '../../config'
import { INTEGRATIONS_QUERY } from './Integrations.graphql'

const config = Config(process.env.REACT_APP_ENV)

const IntegrationStatuses = ['IDLE', 'RUNNING', 'COMPLETED', 'FAILED']

const Integrations = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedStatus, setStatus] = useState(searchParams.get('status'))
  const [selectedApplication, setApplication] = useState(searchParams.get('applicationId'))

  const handleStatusChange = useCallback(
    (status) => {
      setStatus(status)
      const newParams = new URLSearchParams(searchParams)
      if (status) {
        newParams.set('status', status)
      } else {
        newParams.delete('status')
      }
      setSearchParams(newParams)
    },
    [searchParams, setSearchParams]
  )

  const handleApplicationChange = useCallback(
    (applicationId) => {
      setApplication(applicationId)
      const newParams = new URLSearchParams(searchParams)
      if (applicationId) {
        newParams.set('applicationId', applicationId)
      } else {
        newParams.delete('applicationId')
      }
      setSearchParams(newParams)
    },
    [searchParams, setSearchParams]
  )

  const handleStopIntegration = useCallback((integration) => {
    console.log('integration', integration)
    commitMutation(environment, {
      mutation: graphql`
        mutation IntegrationsStopMutation($id: Uuid!, $ownerId: Uuid, $ownerType: ParticipantAssociationType) {
          updateIntegrationById(id: $id, ownerId: $ownerId, ownerType: $ownerType, status: FAILED) {
            id
            ownerId
            ownerType
            status
          }
        }
      `,
      variables: {
        id: integration.id,
        ownerId: integration.ownerId,
        ownerType: integration.ownerType
      },
      onCompleted: () => {
        // Optional: Add success notification
        console.log('Integration stopped')
      },
      onError: (error) => {
        console.error('Failed to stop integration:', error)
        // Optional: Add error notification
      }
    })
  }, [])

  const renderFilterStatus = useCallback(() => {
    return (
      <div className='dropdown'>
        <button type='button' className='btn btn-secondary dropdown-toggle' data-toggle='dropdown'>
          <i className='fe fe-filter mr-2'></i>
          {selectedStatus ?? 'Status'}
        </button>
        <ul className='dropdown-menu' aria-labelledby='dropdownFilterButton'>
          <li>
            <button type='button' className='dropdown-item' onClick={() => handleStatusChange(null)}>
              All
            </button>
          </li>
          {IntegrationStatuses.map((status) => (
            <li key={status}>
              <button type='button' className='dropdown-item' onClick={() => handleStatusChange(status)}>
                {status}
              </button>
            </li>
          ))}
        </ul>
      </div>
    )
  }, [selectedStatus, handleStatusChange])

  const renderFilterApplication = useCallback((integrations) => {
    // Get unique applications
    const applications = [...new Set(integrations
      .map(integration => integration.application)
      .filter(Boolean)
      .map(app => JSON.stringify({ id: app.id, name: app.name }))
    )]
    .map(str => JSON.parse(str))
    .sort((a, b) => a.name.localeCompare(b.name))

    return (
      <div className='dropdown ml-2'>
        <button type='button' className='btn btn-secondary dropdown-toggle' data-toggle='dropdown'>
          <i className='fe fe-filter mr-2'></i>
          {applications.find(app => app.id === selectedApplication)?.name ?? 'Application'}
        </button>
        <ul className='dropdown-menu' aria-labelledby='dropdownFilterButton'>
          <li>
            <button type='button' className='dropdown-item' onClick={() => handleApplicationChange(null)}>
              All
            </button>
          </li>
          {applications.map((app) => (
            <li key={app.id}>
              <button type='button' className='dropdown-item' onClick={() => handleApplicationChange(app.id)}>
                {app.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
    )
  }, [selectedApplication, handleApplicationChange])

  return (
    <QueryRenderer
      environment={environment}
      query={INTEGRATIONS_QUERY}
      variables={{
        status: selectedStatus
      }}
      render={({ error, props }) => {
        if (error) return <Error error={error} />
        if (!props) return <Loader />

        const integrations = props.integrations?.edges?.map((ed) => ed.integration) ?? []
        
        // Filter integrations client-side based on selected application
        const filteredIntegrations = selectedApplication
          ? integrations.filter(integration => integration.application?.id === selectedApplication)
          : integrations

        const totalCount = filteredIntegrations.length

        return (
          <div className='my-3 my-md-5'>
            <div className='container'>
              <div className='row row-cards'>
                <div className='col-lg-12'>
                  <div className='card'>
                    <div className='card-header'>
                      <div>
                        <h3 className='card-title'>{`Integrations (${totalCount})`}</h3>
                      </div>
                      <div className='ml-auto d-flex'>
                        {renderFilterStatus()}
                        {renderFilterApplication(integrations)}
                      </div>
                    </div>
                    <div className='table-responsive'>
                      <table className='table card-table table-striped table-vcenter'>
                        <thead>
                          <tr>
                            <th className='d-none d-lg-table-cell'>Name</th>
                            <th className='d-none d-lg-table-cell'>Application</th>
                            <th className='d-none d-lg-table-cell'>Status</th>
                            <th className='d-none d-lg-table-cell'>Scheduled At</th>
                            <th className='d-none d-lg-table-cell'>Updated At</th>
                            <th className='d-none d-lg-table-cell'>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredIntegrations.map((integration) => {
                            return (
                              <tr key={integration.id}>
                                <td><a target='_blank' href={`${config.partnerUrl}/settings/integrations/${integration.id}/?impersonationToken=${integration.partner?.impersonationToken}&redirect=/settings/integrations/${integration.id}`} rel='noreferrer'>
                                  {integration.name || 'Unknown'}
                                </a></td>
                                <td>{integration.application?.name || 'Unknown'}</td>
                                <td>{integration.status}</td>
                                <td>{integration.scheduledAt}</td>
                                <td>{integration.updatedAt}</td>
                                <td>
                                  {integration.status === 'RUNNING' ? (
                                    <button type='button' className='btn btn-danger' onClick={() => handleStopIntegration(integration)}>
                                      <i className='fe fe-stop-circle'></i>
                                    </button>
                                  ) : undefined}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export { Integrations }
