import graphql from 'babel-plugin-relay/macro'

const INTEGRATIONS_QUERY = graphql`
  query IntegrationsQuery($status: IntegrationStatus) {
    integrations: allIntegrations(first: 666, condition: { status: $status }) {
      totalCount
      edges {
        integration: node {
          id
          ownerId
          ownerType
          name
          application: applicationByApplicationId {
            id
            name
          }
          status
          updatedAt
          scheduledAt
          partner: partnerByPartnerId {
            id
            name
            impersonationToken
          }
        }
      }
    }
  }
`

export { INTEGRATIONS_QUERY }
